﻿/* globals analytics, SegmentDTO, visitorAPI*/
import jquery from 'jquery';
import HabEvents from '../../Global/habitaclia-events';
import cookieService from '../../Global/cookie-service';

export default class SegmentTracking {

    constructor(SegmentDTO) {
            this.SegmentDTO = SegmentDTO;
            this.loginTypeCookieName = 'logintype';
            this.sdrnUserId = this.SegmentDTO.userId !== "0" ? "sdrn:habitaclia.com:user:" + this.SegmentDTO.userId : {};
            this.registerEvents();
    }

    getDataFromDetail(data) {
        if (data && data.isDetail && data.dataset) {
            return data.dataSet;
        } else {
            const detail = document.getElementById('ficha');
            if (detail) {
                return detail.dataset;
            } else if (data.codigoAnuncio.indexOf('.fid') === 0) {
                const card = document.querySelector(data.codigoAnuncio);
                return card.dataset;
            } else {
                const card = document.getElementById('id' + data.codigoAnuncio);
                return card.dataset;
            }
        }
    }

    getPromotionUUIDFromAdId(adId) {
        if (this.SegmentDTO.pageViewedEventData.products.length > 0) {
            const productFromAdId = this.SegmentDTO.pageViewedEventData.products.find(product => product.id === adId);
            if (!productFromAdId) return undefined;
            return productFromAdId.realestate_promotion_id;
        }

        return undefined;
    }

    getProductTrackingTypeFromPromotionUUID({ adId, id, realEstateAdId }) {
        const _adId = adId || id;
        
        const productTrackingType = {
            category: 'ads',
            id: _adId,
            realestateadid: realEstateAdId,
        };
        
        const productFromAdId = this.getPromotionUUIDFromAdId(_adId);
        
        if (!productFromAdId) { return productTrackingType; }
        
        productTrackingType.realestate_promotion_id = productFromAdId;
        return productTrackingType;
    }

    getLoginType() {
        return cookieService.getCookie(this.loginTypeCookieName);
    }

    registerEvents() {
        let options = {};
        if (this.SegmentDTO.userId) {
            options.traits = { userId: this.sdrnUserId };
        }
        window.SegmentOptions = options;
        window.sui.analytics.page(this.SegmentDTO.pageViewedEventName, this.SegmentDTO.pageViewedEventData, options);
        if (this.SegmentDTO.isUserLogged) {
            window.sui.analytics.identify(this.sdrnUserId, { userId: this.sdrnUserId, loginType: this.getLoginType(), email: this.SegmentDTO.userEmail });
        }
        jquery.subscribe(HabEvents.EventsEnum.SegmentTrackLead, (e, data) => {
            const { codigoAnuncio, realEstateAdId } = data;
            var platform = this.SegmentDTO.pageViewedEventData.platform;
            var page_type = this.SegmentDTO.pageViewedEventData.page_type;
            const { esparticular, id, propertytype, propertysubtype, selltype, transaction } = this.getDataFromDetail(data);
            
            const products = this.getProductTrackingTypeFromPromotionUUID({ adId: codigoAnuncio, id, realEstateAdId});

            const segmentData = {
                ad_publisher_type: esparticular,
                form_name: 'contact_form',
                id: codigoAnuncio,
                property: propertytype,
                property_sub: propertysubtype,
                sell_type: selltype,
                transaction: transaction,
                products: [products],
                platform: platform,
                page_type: page_type
            };
            window.sui.analytics.track('Ad Replied Email', segmentData, options);
        });

        jquery.subscribe(HabEvents.EventsEnum.FavoritoAnadirAccion, () => {
            const { language, platform, site, vertical, page_type } = this.SegmentDTO.pageViewedEventData;
            const segmentData = { language, platform, site, vertical, page_type };
            window.sui.analytics.track('Favourite Added', segmentData, options);
        });

        jquery.subscribe(HabEvents.EventsEnum.FavoritoEliminarAccion, () => {
            const { language, platform, site, vertical, page_type } = this.SegmentDTO.pageViewedEventData;
            const segmentData = { language, platform, site, vertical, page_type };
            window.sui.analytics.track('Favourite Removed', segmentData,  options);
        });

        jquery.subscribe(HabEvents.EventsEnum.FavoritoAnadido, (e, codigoAnuncio, realEstateAdId) => {
            const data = { codigoAnuncio };
            const { propertytype, propertysubtype, selltype, transaction, publisherid, id } = this.getDataFromDetail(data);
            var page_type = this.SegmentDTO.pageViewedEventData.page_type;
            const { language, platform, site, vertical } = this.SegmentDTO.pageViewedEventData;
           
            const products = this.getProductTrackingTypeFromPromotionUUID({ adId: codigoAnuncio, id, realEstateAdId});
           
            const segmentData = {
                ad_publisher_id: publisherid,
                language,
                platform,
                site,
                vertical,
                property: propertytype,
                property_sub: propertysubtype,
                sell_type: selltype,
                transaction,
                products: [products],
                page_type: page_type
            };
            window.sui.analytics.track('Favourite Added Success', segmentData,  options);
        });

        jquery.subscribe(HabEvents.EventsEnum.FavoritoEliminado, (e, codigoAnuncio, realEstateAdId) => {
            const data = { codigoAnuncio };
            const { propertytype, propertysubtype, selltype, transaction, publisherid, id} = this.getDataFromDetail(data);
            var page_type = this.SegmentDTO.pageViewedEventData.page_type;
            const { language, platform, site, vertical } = this.SegmentDTO.pageViewedEventData;
            
            const products = this.getProductTrackingTypeFromPromotionUUID({ adId: codigoAnuncio, id, realEstateAdId});
        
            const segmentData = {
                ad_publisher_id: publisherid,
                language,
                platform,
                site,
                vertical,
                property: propertytype,
                property_sub: propertysubtype,
                sell_type: selltype,
                transaction,
                products: [products],
                page_type: page_type
            };
            window.sui.analytics.track('Favourite Removed Success', segmentData, options);
        });

        jquery.subscribe(HabEvents.EventsEnum.PropertyValuationLandingButtonClicked, (e, pageType) => {
            const { language, platform, site, vertical } = this.SegmentDTO.pageViewedEventData;
            const segmentData = {
                channel: 'ads',
                label: 'property_valuation',
                page_type: pageType,
                language,
                platform,
                site,
                vertical
            };
            window.sui.analytics.track('Property Valuation Landing Button Clicked', segmentData, options);
        });

        jquery.subscribe(HabEvents.EventsEnum.SellWithAgencyButtonClicked, (e, pageType) => {
            const { language, platform, site, vertical } = this.SegmentDTO.pageViewedEventData;
            const segmentData = {
                channel: 'ads',
                label: 'sell_with_agency',
                page_type: pageType,
                language,
                platform,
                site,
                vertical
            };
            window.sui.analytics.track('Sell with Agency Button Clicked', segmentData, options);
        });

        jquery.subscribe(HabEvents.EventsEnum.AdDiscarded, (_, codigoAnuncio) => {
            const { language, platform, site, vertical } = this.SegmentDTO.pageViewedEventData;
            var page_type = this.SegmentDTO.pageViewedEventData.page_type;
            const data = { codigoAnuncio };
            const {
              esparticular,
              propertytype,
              propertysubtype,
              transaction,
              id,
            } = this.getDataFromDetail(data);

            const segmentData = {
                ad_publisher_type: esparticular,
                id,
                property: propertytype,
                property_sub: propertysubtype,
                page_type: page_type,
                transaction: transaction,
                language,
                platform,
                site,
                vertical
            };

            window.sui.analytics.track("Ad Discarded", segmentData, options);
        });

        jquery.subscribe(HabEvents.EventsEnum.AdDiscardedSuccess, (_, IdAnuncio) => {
            const { language, platform, site, vertical } = this.SegmentDTO.pageViewedEventData;
            var page_type = this.SegmentDTO.pageViewedEventData.page_type;
            const data = { IdAnuncio };
            const {
              esparticular,
              propertytype,
              propertysubtype,
              transaction,
              id,
            } = this.getDataFromDetail(data);

            const segmentData = {
                ad_publisher_type: esparticular,
                id,
                property: propertytype,
                property_sub: propertysubtype,
                page_type: page_type,
                transaction: transaction,
                language,
                platform,
                site,
                vertical,
            };
            window.sui.analytics.track("Ad Discarded Success", segmentData, options);
        });

        jquery.subscribe(HabEvents.EventsEnum.AdRecovered, (_, codigoAnuncio) => {
            const { language, platform, site, vertical } = this.SegmentDTO.pageViewedEventData;
            const data = { codigoAnuncio };
            const {
              esparticular,
              propertytype,
              propertysubtype,
              transaction,
              id,
            } = this.getDataFromDetail(data);

            const segmentData = {
              ad_publisher_type: esparticular,
              id,
              property: propertytype,
              property_sub: propertysubtype,
              page_type: "my_account",
              transaction: transaction,
              language,
              platform,
              site,
              vertical,
            };

            window.sui.analytics.track("Ad Recovered", segmentData, options);
        });

        jquery.subscribe(HabEvents.EventsEnum.AlertAdded, () => {
            const { language, platform, site, transaction, vertical } = this.SegmentDTO.pageViewedEventData;
            var page_type = this.SegmentDTO.pageViewedEventData.page_type;

            const segmentData = {
                alert_type: 'FROM_LIST_PAGE',
                language,
                platform,
                site,
                transaction,
                vertical,
                page_type
            };

            window.sui.analytics.track("Alert Added", segmentData, options);
        });

        jquery.subscribe(HabEvents.EventsEnum.AlertAddedSuccess, () => {
            const { language, platform, site, transaction, vertical } = this.SegmentDTO.pageViewedEventData;
            var page_type = this.SegmentDTO.pageViewedEventData.page_type;

            const segmentData = {
                alert_type: 'FROM_LIST_PAGE',
                form_name: 'alert',
                language,
                platform,
                site,
                transaction,
                vertical,
                page_type
            };

            window.sui.analytics.track("Alert Added Success", segmentData, options);
        });

        jquery.subscribe(HabEvents.EventsEnum.PublishAd, (e, url) => {
            const language = this.SegmentDTO.pageViewedEventData.language;
            const platform = this.SegmentDTO.pageViewedEventData.platform;
            const site = this.SegmentDTO.pageViewedEventData.site;
            const vertical = this.SegmentDTO.pageViewedEventData.vertical;  
            const pageType = this.SegmentDTO.pageViewedEventData.page_type;
            const segmentData = {
                channel: 'ad_post',
                label: 'pta_button',
                page_type: pageType,
                language,
                platform,
                site,
                vertical
            };
            window.sui.analytics.track('PTA Button Clicked', segmentData, options).then(function () {
                window.location.href = url;
            });
        });
	}
}

/* jshint unused: false */
if(window.SegmentDTO){
    const segmentTracking = new SegmentTracking(window.SegmentDTO);
} else {
    window.segmentTracking= SegmentTracking;
}